// import { useEffect, useState } from 'react';
// import './App.css';

// const tg = window.Telegram.WebApp;

// function App() {
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     tg.ready();
//     console.log('Telegram Web App ready');
//     const userData = tg.initDataUnsafe.user;
//     console.log('User Data:', userData);
//     setUser(userData);

//     if (userData) {
//       saveUser(userData);
//     }
//   }, []);


//   const saveUser = async (user) => {
//     try {
//       const response = await fetch('https://ftn.iotlab.am/api/users', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(user)
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('User saved:', data);
//       } else {
//         console.error('Failed to save user');
//       }
//     } catch (error) {
//       console.error('Error saving user:', error);
//     }
//   };

//   const onClose = () => {
//     tg.close();
//   };

//   // const addPoints = async () => {
//   //   // user.id
//   //   // if (user) {
//   //   try {
//   //     const response = await fetch(`https://ftn.iotlab.am/api/users/1074699355/points`, {
//   //       method: 'POST',
//   //       headers: {
//   //         'Content-Type': 'application/json'
//   //       },
//   //       body: JSON.stringify({ points: 1 })
//   //     });

//   //     if (response.ok) {
//   //       const data = await response.json();
//   //       console.log('Points added:', data);
//   //     } else {
//   //       console.error('Failed to add points');
//   //     }
//   //   } catch (error) {
//   //     console.error('Error adding points:', error);
//   //   }
//   //   // }
//   // };

//   const addPoints = async () => {
//     try {
//       const response = await fetch(`https://ftn.iotlab.am/api/users/1074699355/points`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ points: 1 })
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Points added:', data);
//       } else {
//         console.error('Failed to add points:', await response.text()); // Log response for debugging
//       }
//     } catch (error) {
//       console.error('Error adding points:', error);
//     }
//   };


//   return (
//     <div className="App">
//       work
//       <button onClick={onClose}>close</button>
//       <button onClick={addPoints}>Tap</button>
//       <p>{user ? JSON.stringify(user) : 'Loading user information...'}</p>
//     </div>
//   );
// }

// export default App;



/* import { useEffect, useState } from 'react';
import './App.css';
// import { rollDice } from './dicelogic'; // Import the dice logic
// import './dicelogic'; // Ensure you have access to the dice logic

const tg = window.Telegram.WebApp;

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    tg.ready();
    console.log('Telegram Web App ready');
    const userData = tg.initDataUnsafe.user;
    console.log('User Data:', userData);
    setUser(userData);

    // Debug user ID
    console.log('User ID:', userData ? userData.id : 'No user data');

    if (userData) {
      saveUser(userData);
    }
  }, []);

  const saveUser = async (user) => {
    try {
      const response = await fetch('https://ftn.iotlab.am/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User saved:', data);
      } else {
        console.error('Failed to save user:', await response.text()); // Log response for debugging
      }
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const onClose = () => {
    tg.close();
  };

  const addPoints = async () => {
    if (user && user.id) {
      try {
        const response = await fetch(`https://ftn.iotlab.am/api/users/${user.id}/points`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ points: 1 })
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Points added:', data);
        } else {
          console.error('Failed to add points:', await response.text()); // Log response for debugging
        }
      } catch (error) {
        console.error('Error adding points:', error);
      }
    } else {
      console.error('User ID is not available');
    }
  };

  const handleRollDice = () => {
    const result = rollDice();
    setDiceResult(result);

    // Update the dice DOM classes accordingly
    updateDiceUI(result.diceOne, result.diceTwo);
  };

  const updateDiceUI = (diceOne, diceTwo) => {
    const elDiceOne = document.getElementById('dice1');
    const elDiceTwo = document.getElementById('dice2');

    for (let i = 1; i <= 6; i++) {
      elDiceOne.classList.remove('show-' + i);
      if (diceOne === i) {
        elDiceOne.classList.add('show-' + i);
      }
      elDiceTwo.classList.remove('show-' + i);
      if (diceTwo === i) {
        elDiceTwo.classList.add('show-' + i);
      }
    }
  };

  return (
    <div className="App">
      <div class="game">
        <div class="container">
          <div id='dice1' class="dice dice-one">
            <div id="dice-one-side-one" class='side one'>
              <div class="dot one-1"></div>
            </div>
            <div id="dice-one-side-two" class='side two'>
              <div class="dot two-1"></div>
              <div class="dot two-2"></div>
            </div>
            <div id="dice-one-side-three" class='side three'>
              <div class="dot three-1"></div>
              <div class="dot three-2"></div>
              <div class="dot three-3"></div>
            </div>
            <div id="dice-one-side-four" class='side four'>
              <div class="dot four-1"></div>
              <div class="dot four-2"></div>
              <div class="dot four-3"></div>
              <div class="dot four-4"></div>
            </div>
            <div id="dice-one-side-five" class='side five'>
              <div class="dot five-1"></div>
              <div class="dot five-2"></div>
              <div class="dot five-3"></div>
              <div class="dot five-4"></div>
              <div class="dot five-5"></div>
            </div>
            <div id="dice-one-side-six" class='side six'>
              <div class="dot six-1"></div>
              <div class="dot six-2"></div>
              <div class="dot six-3"></div>
              <div class="dot six-4"></div>
              <div class="dot six-5"></div>
              <div class="dot six-6"></div>
            </div>
          </div>
        </div>
        <div class="container">
          <div id='dice2' class="dice dice-two">
            <div id="dice-two-side-one" class='side one'>
              <div class="dot one-1"></div>
            </div>
            <div id="dice-two-side-two" class='side two'>
              <div class="dot two-1"></div>
              <div class="dot two-2"></div>
            </div>
            <div id="dice-two-side-three" class='side three'>
              <div class="dot three-1"></div>
              <div class="dot three-2"></div>
              <div class="dot three-3"></div>
            </div>
            <div id="dice-two-side-four" class='side four'>
              <div class="dot four-1"></div>
              <div class="dot four-2"></div>
              <div class="dot four-3"></div>
              <div class="dot four-4"></div>
            </div>
            <div id="dice-two-side-five" class='side five'>
              <div class="dot five-1"></div>
              <div class="dot five-2"></div>
              <div class="dot five-3"></div>
              <div class="dot five-4"></div>
              <div class="dot five-5"></div>
            </div>
            <div id="dice-two-side-six" class='side six'>
              <div class="dot six-1"></div>
              <div class="dot six-2"></div>
              <div class="dot six-3"></div>
              <div class="dot six-4"></div>
              <div class="dot six-5"></div>
              <div class="dot six-6"></div>
            </div>
          </div>
        </div>
        <div id='roll' class='roll-button'><button>Roll dice!</button></div>
      </div>
      <button onClick={onClose}>close</button>
      <button onClick={addPoints}>Tappp</button>
      <p>{user ? JSON.stringify(user) : 'Loading user information...'}</p>
    </div>
  );
}

export default App; */




import React, { useState, useEffect } from 'react';
import './App.css';
import { rollDice } from './dicelogic'; // Import the dice logic

const tg = window.Telegram.WebApp;

const API_URL = 'https://ftn.iotlab.am'

function App() {
  const [user, setUser] = useState(null);
  const [diceResult, setDiceResult] = useState({ diceOne: 1, diceTwo: 1 }); // Initial dice state

  useEffect(() => {
    tg.ready();
    console.log('Telegram Web App ready');
    const userData = tg.initDataUnsafe.user;
    // const userData = {
    //   'id': 1074699355,
    //   'username': 'davbaghdasaryannnn'
    // }
    setUser(userData);

    if (userData) {
      saveUser(userData);
    }
  }, []);

  const saveUser = async (user) => {
    try {
      const response = await fetch(`${API_URL}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User saved:', data);
      } else {
        console.error('Failed to save user');
      }
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleRollDice = async () => {
    const result = rollDice();
    const totalDiceValue = result.diceOne + result.diceTwo;
    const remainingTimeText = document.getElementById('remainingTimeText');

    // Attempt to add points and get result
    const { success, remainingTime } = await addPoints(totalDiceValue);

    // Only update UI if coins were added successfully
    if (success) {
      setDiceResult(result);
      updateDiceUI(result.diceOne, result.diceTwo);
    } else if (remainingTime) {
      // Display the remaining time message if coins weren't added due to the time restriction
      console.log(`Please wait before claiming coins again. Remaining time: ${remainingTime} ms`);
      // alert(`Please wait before claiming coins again. Remaining time: ${Math.ceil(remainingTime / 1000)} seconds`);
      remainingTimeText.innerHTML = `Remaining time: ${Math.ceil(remainingTime / 1000)} seconds`;
    } else {
      console.log('Coins not added; UI will not be updated.');
    }
  };

  const updateDiceUI = (diceOne, diceTwo) => {
    const elDiceOne = document.getElementById('dice1');
    const elDiceTwo = document.getElementById('dice2');

    for (var i = 1; i <= 6; i++) {
      elDiceOne.classList.remove('show-' + i);
      if (diceOne === i) {
        elDiceOne.classList.add('show-' + i);
      }
    }

    for (var k = 1; k <= 6; k++) {
      elDiceTwo.classList.remove('show-' + k);
      if (diceTwo === k) {
        elDiceTwo.classList.add('show-' + k);
      }
    }
  };



  const onClose = () => {
    tg.close();
  };

  const addPoints = async (value) => {
    if (user && user.id) {
      try {
        const response = await fetch(`https://ftn.iotlab.am/api/users/${user.id}/coins`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ coins: value })
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Coins added:', data);
          return { success: true };
        } else {
          const errorData = await response.json();
          console.error('Failed to add coins:', errorData);
          return {
            success: false,
            remainingTime: errorData.remainingTime || null
          };
        }
      } catch (error) {
        console.error('Error adding coins:', error);
        return { success: false };
      }
    } else {
      console.error('User ID is not available');
      return { success: false };
    }
  };

  return (
    <div className="App">
      <h1>Dice Game</h1>
      <div className="game">
        <div className="container">
          <h1 id="remainingTimeText"></h1>
          <div id="dice1" className="dice dice-one">
            <div className="side one">
              <div className="dot one-1"></div>
            </div>
            <div className="side two">
              <div className="dot two-1"></div>
              <div className="dot two-2"></div>
            </div>
            <div className="side three">
              <div className="dot three-1"></div>
              <div className="dot three-2"></div>
              <div className="dot three-3"></div>
            </div>
            <div className="side four">
              <div className="dot four-1"></div>
              <div className="dot four-2"></div>
              <div className="dot four-3"></div>
              <div className="dot four-4"></div>
            </div>
            <div className="side five">
              <div className="dot five-1"></div>
              <div className="dot five-2"></div>
              <div className="dot five-3"></div>
              <div className="dot five-4"></div>
              <div className="dot five-5"></div>
            </div>
            <div className="side six">
              <div className="dot six-1"></div>
              <div className="dot six-2"></div>
              <div className="dot six-3"></div>
              <div className="dot six-4"></div>
              <div className="dot six-5"></div>
              <div className="dot six-6"></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div id="dice2" className="dice dice-two">
            <div className="side one">
              <div className="dot one-1"></div>
            </div>
            <div className="side two">
              <div className="dot two-1"></div>
              <div className="dot two-2"></div>
            </div>
            <div className="side three">
              <div className="dot three-1"></div>
              <div className="dot three-2"></div>
              <div className="dot three-3"></div>
            </div>
            <div className="side four">
              <div className="dot four-1"></div>
              <div className="dot four-2"></div>
              <div className="dot four-3"></div>
              <div className="dot four-4"></div>
            </div>
            <div className="side five">
              <div className="dot five-1"></div>
              <div className="dot five-2"></div>
              <div className="dot five-3"></div>
              <div className="dot five-4"></div>
              <div className="dot five-5"></div>
            </div>
            <div className="side six">
              <div className="dot six-1"></div>
              <div className="dot six-2"></div>
              <div className="dot six-3"></div>
              <div className="dot six-4"></div>
              <div className="dot six-5"></div>
              <div className="dot six-6"></div>
            </div>
          </div>
        </div>
        <div id="roll" className="roll-button">
          <button onClick={handleRollDice}>Roll dice!</button>
        </div>
      </div>
      <button onClick={onClose}>Close</button>
      {/* <button onClick={addPoints}>Tap</button> */}
      {/* <p>{user ? JSON.stringify(user) : 'Loading user information...'}</p> */}
    </div>
  );
}

export default App;
