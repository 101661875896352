// src/dicelogic.js

export function rollDice() {
    const diceOne = Math.floor((Math.random() * 6) + 1);
    const diceTwo = Math.floor((Math.random() * 6) + 1);

    console.log('Dice Results:', diceOne, diceTwo); // For debugging

    return { diceOne, diceTwo };
}
